import { Inject, Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { LogService } from 'src/app/services';

import { ID_SERVICE_TOKEN, IIdService, IScenarioService, SCENARIO_SERVICE_TOKEN } from 'src/app/interface';
import { ServiceModule } from './service.module';

@Injectable({
  providedIn: ServiceModule
})
export class PeriodService {
  private _initialized$: Subject<boolean>;
  private _updated$: Subject<number>;
  private _selectedPeriod: number = -1;
  private _selectedPeriod$!: Subject<number> ;
  private _firstPeriod: number | undefined;
  private _lastPeriod: number  | undefined;
  private _firstDecisionPeriod: number  | undefined;
  private console = LogService.initialize('PeriodService');

  constructor(@Inject(ID_SERVICE_TOKEN) private idService: IIdService,
              @Inject(SCENARIO_SERVICE_TOKEN) private scenarioService: IScenarioService) {
    this._selectedPeriod$ = new ReplaySubject(1);
    this._updated$ = new ReplaySubject<number>(1);
    this._initialized$ = new ReplaySubject<boolean>(1);

    this.idService.initialized$.subscribe(() => {
      // this.console.log(`challenge()`, this.idService.challenge);

      if (this.idService.challenge) {
        this._firstDecisionPeriod = this.idService.challenge.firstPeriod;
        this._lastPeriod = this.idService.challenge.lastPeriod;
        this.scenarioService.initialized$.subscribe(_ => {
          this._firstPeriod = this.scenarioService.firstHistoricPeriod;
          this.console.log(`INITIALIZED`);
          this._initialized$.next(true);
          this._initialized$.complete();
        });
      }

      this.idService.updated$.subscribe(challenge => {
        if (challenge) {
          //this._firstPeriod does not change (from scenario, and in a course scenario cannot be updated)
          this._firstDecisionPeriod = challenge.firstPeriod;
          this._lastPeriod = challenge.lastPeriod;
          this._updated$.next(challenge.lastPeriod);
        }
      });
    });
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  public get updated$(): Subject<number> {
    return this._updated$;
  }

  public get selectedPeriod$(): Subject<number> {
    return this._selectedPeriod$;
  }

  public get selectedPeriod(): number {
    return this._selectedPeriod;
  }

  public get firstPeriod(): number | undefined {
    return this._firstPeriod;
  }

  public get firstDecisionPeriod(): number | undefined {
    return this._firstDecisionPeriod;
  }

  public get LastPeriod(): number | undefined {
    return this._lastPeriod;
  }

  public setSelectedPeriod(newPeriod: number): void {
    this._selectedPeriod = newPeriod;
    this._selectedPeriod$.next(newPeriod);
  }

  private setLimits(firstPeriod: number, lastPeriod: number): void {
    this._firstPeriod = firstPeriod;
    this._lastPeriod = lastPeriod;
  }
}
