/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum ColorTheme {

    yellowAerospace = 'rgba(255, 197, 48, 1)',
    blueAppliances = 'rgba(51, 122, 234, 1)',
    redAutomobile = 'rgba(245, 39, 44, 1)',
    greenEnergy = 'rgba(68, 176, 27, 1)',
    greyIndustrial = 'rgba(160, 160, 160, 1)',
    yellowFollowers = 'rgba(255, 197, 48, 1)',
    blueAdopters = 'rgba(51, 122, 234, 1)',
    greenInnovators = 'rgba(68, 176, 27, 1)',
    technicalChannel = 'rgba(71, 0, 122, 1)',
    generalChannel = 'rgba(184, 119, 57, 1)',
    directChannel = 'rgba(27, 110, 27, 1)',
    yellowSemantic = 'rgba(255, 197, 48, 1)',
    blueSemantic = 'rgba(51, 122, 234, 1)',
    redSemantic = 'rgba(245, 39, 44, 1)',
    greenSemantic = 'rgba(68, 176, 27, 1)',
    greySemantic = 'rgba(160, 160, 160, 1)',
    brownSemantic = 'rgba(186, 75, 16, 1)',
    yellowMds = 'rgba(255, 197, 48, 1)',
    blueMds = 'rgba(51, 122, 234, 1)',
    redMds = 'rgba(245, 39, 44, 1)',

    team01 = 'rgba(0, 133, 166, 1)',
    team02 = 'rgba(178, 0, 71, 1)',
    team03 = 'rgba(184, 204, 102, 1)',
    team04 = 'rgba(0, 217, 202, 1)',
    team05 = 'rgba(172, 180, 230, 1)',
    team06 = 'rgba(255, 0, 170, 1)',
    team07 = 'rgba(64, 0, 0, 1)',
    team08 = 'rgba(242, 186, 121, 1)',
    team09 = 'rgba(204, 109, 0, 1)',
    team10 = 'rgba(80, 77, 102, 1)',
    team11 = 'rgba(89, 0, 60, 1)',
    team12 = 'rgba(200, 60, 60, 1)',
    team13 = 'rgba(235, 201, 0, 1)',
    team14 = 'rgba(255, 10, 10, 1)',

    team01l = 'rgba(0, 150, 187, 1)',
    team02l = 'rgba(196, 0, 79, 1)',
    team03l = 'rgba(188, 207, 114, 1)',
    team04l = 'rgba(0, 236, 218, 1)',
    team05l = 'rgba(185, 192, 234, 1)',
    team06l = 'rgba(255, 23, 180, 1)',
    team07l = 'rgba(77, 0, 0, 1)',
    team08l = 'rgba(244, 195, 130, 1)',
    team09l = 'rgba(214, 122, 0, 1)',
    team10l = 'rgba(89, 85, 112, 1)',
    team11l = 'rgba(100, 0, 72, 1)',
    team12l = 'rgba(208, 70, 70, 1)',
    team13l = 'rgba(242, 211, 0, 1)',
    team14l = 'rgba(255, 20, 20, 1)',

    team01d = 'rgba(0, 124, 166, 1)',
    team02d = 'rgba(168, 0, 67, 1)',
    team03d = 'rgba(179, 200, 91, 1)',
    team04d = 'rgba(0, 206, 190, 1)',
    team05d = 'rgba(164, 173, 227, 1)',
    team06d = 'rgba(240, 0, 163, 1)',
    team07d = 'rgba(54, 0, 0, 1)',
    team08d = 'rgba(230, 180, 118, 1)',
    team09d = 'rgba(194, 105, 0, 1)',
    team10d = 'rgba(75, 72, 90, 1)',
    team11d = 'rgba(80, 0, 53, 1)',
    team12d = 'rgba(184, 55, 55, 1)',
    team13d = 'rgba(220, 189, 0, 1)',
    team14d = 'rgba(235, 9, 9, 1)',

    // Colors for firms id
    Firm_C = 'rgba(244, 67, 54, .75)',
    Firm_E = 'rgba(33, 150, 243, .75)',
    Firm_N = 'rgba(255, 152, 0, .75)',
    Firm_T = 'rgba(156, 39, 176, .75)',
    Firm_R = 'rgba(76, 175, 80, .75)',
    Firm_X = 'rgba(241, 198, 47, .75)',
    Firm_1 = 'rgba(244, 67, 54, .75)',
    Firm_2 = 'rgba(33, 150, 243, .75)',
    Firm_3 = 'rgba(255, 152, 0, .75)',
    Firm_4 = 'rgba(156, 39, 176, .75)',
    Firm_5 = 'rgba(76, 175, 80, .75)',
    Firm_6 = 'rgba(241, 198, 47, .75)',
    Firm_7 = 'rgba(0, 215, 238, .75)',

    // Variants
    redFirm = 'rgba(244, 67, 54, 1)',
    blueFirm = 'rgba(33, 150, 243, 1)',
    orangeFirm = 'rgba(255, 152, 0, 1)',
    purpleFirm = 'rgba(156, 39, 176, 1)',
    greenFirm = 'rgba(76, 175, 80, 1)',
    yellowFirm = 'rgba(241, 198, 47, 1)',
    greyFirm = 'rgba(170, 170, 170, 1)',
    cyanFirm = 'rgba(0, 215, 238, 1)',
    darkRedFirm = 'rgba(186, 0, 13, 1)',
    lightRedFirm = 'rgba(255, 121, 97, 1)',
    darkBlueFirm = 'rgba(0, 105, 192, 1)',
    lightBlueFirm = 'rgba(110, 198, 255, 1)',
    darkOrangeFirm = 'rgba(198, 105, 0, 1)',
    lightOrangeFirm = 'rgba(255, 201, 71, 1)',
    darkPurpleFirm = 'rgba(106, 0, 128, 1)',
    lightPurpleFirm = 'rgba(208, 92, 227, 1)',
    darkGreenFirm = 'rgba(8, 127, 35, 1)',
    lightGreenFirm = 'rgba(128, 226, 126, 1)',
    darkYellowFirm = 'rgba(230, 182, 15, 1)',
    lightYellowFirm = 'rgba(244, 210, 91, 1)',
    darkGreyFirm = 'rgba(120, 120, 120, 1)',
    lightGreyFirm = 'rgba(210, 210, 210, 1)',
    darkCyanFirm = 'rgba(0, 174, 193, 1)',
    lightCyanFirm = 'rgba(94, 239, 255, 1)',

    black = 'rgba(0, 0, 0, 1)',
    grey = 'rgba(170, 170, 170, 1)',
    hover = 'rgba(34, 34, 34, 1)',
    decision = 'rgba(255, 160, 0, 1)', // = $app-primary TODO@NST
    background = 'rgba(250, 250, 250, 1)',
}
