/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, MaybeAsync, Resolve, RouterStateSnapshot } from '@angular/router';
import { combineLatest, map, ReplaySubject, Subject } from 'rxjs';
import { BioStringApi } from 'src/app/api/string/bio-string-api';
// eslint-disable-next-line max-len
import { InitiativePrefixesBioPharmaInitiativeKeyBioPharmaInitiative, InsightBioPharma, MailKeyBioPharmaMail, MenuEntryKeyBioPharmaMenuEntry, StringKeyBioPharmaStringValue, ToolBioPharma } from 'src/app/generated/model/scenario';
import { IStringService } from 'src/app/interface';
import { DictionaryOf } from 'src/app/model';

@Injectable({
  providedIn: 'root'
})
export class BioStringService implements IStringService, Resolve<boolean> {
  private _initialized$: Subject<boolean>;
  private _strings: DictionaryOf<string> = {};
  private _initiatives: DictionaryOf<InitiativePrefixesBioPharmaInitiativeKeyBioPharmaInitiative> = {};
  private _menuEntries: DictionaryOf<MenuEntryKeyBioPharmaMenuEntry> = {};
  private _insights: DictionaryOf<InsightBioPharma> = {};
  private _tools: DictionaryOf<ToolBioPharma> = {};
  private _mails: DictionaryOf<MailKeyBioPharmaMail> = {};

  private _stringValues: StringKeyBioPharmaStringValue[] = [];
  private _initiativeValues: InitiativePrefixesBioPharmaInitiativeKeyBioPharmaInitiative[] = [];
  private _insightValues: InsightBioPharma[] = [];
  private _toolValues: ToolBioPharma[] = [];
  private _mailValues: MailKeyBioPharmaMail[] = [];
  private _menuEntryValues: MenuEntryKeyBioPharmaMenuEntry[] = [];

  constructor(private stringApi: BioStringApi) {
    console.log(`BioStringService.constructor()`);
    this._initialized$ = new ReplaySubject<boolean>(1);

    combineLatest([
      this.stringApi.getMails(),
      this.stringApi.getStringValues(),
      this.stringApi.getInitiatives(),
      this.stringApi.getInsights(),
      this.stringApi.getTools(),
      this.stringApi.getMenuEntries()]).pipe(
        // combineLatest supports more than 5 parameters but subscribe do not!
        map(([mails, stringValues, initiative, insight, tool, menuEntries]) => {
          return { mails, stringValues, initiative, insight, tool, menuEntries };
        })
      ).subscribe(result => {
        const stringValues = result.stringValues;
        const initiative = result.initiative;
        const insight = result.insight;
        const tool = result.tool;
        const menuEntries = result.menuEntries;
        const mails = result.mails;

        if (mails && mails.length > 0)
        this._mailValues = mails.map(p => ({
          key: p.key,
          content: p.content?.replaceCompanyAndBrandName(),
          sender: p.sender?.replaceCompanyAndBrandName(),
          subject: p.subject?.replaceCompanyAndBrandName(),
        }));
        this._mailValues.forEach(s => {
          if (s && s.key) {
            this._mails[s.key] = s;
          }
        });

        this._stringValues = stringValues;
        if (stringValues && stringValues.length > 0)
        this._stringValues.forEach(s => {
          if (s && s.name && s.value) {
            this._strings[s.name] = s.value;
          }
        });

        this._initiativeValues = initiative;
        if (initiative && initiative.length > 0)
        this._initiativeValues.forEach(s => {
          if (s && s.key) {
            this._initiatives[s.key] = s;
          }
        });

        this._insightValues = insight;
        if (insight && insight.length > 0)
        this._insightValues.forEach(s => {
          if (s && s.key) {
            this._insights[s.key] = s;
          }
        });

        if (tool && tool.length > 0) {
          this._toolValues = tool;

          this._toolValues.forEach(s => {
            if (s && s.key) {
              this._tools[s.key] = s;
            }
          });
        }


        if (menuEntries && menuEntries.length > 0) {
          const replacedMenuEntries = this.replaceFieldsMenuEntries(menuEntries);

          this._menuEntryValues = replacedMenuEntries;
          this.flatten(replacedMenuEntries);
        }


        console.log(`BioStringService INITIALIZED`);
        this._initialized$.next(true);
      });
  }

  public get strings(): DictionaryOf<string> {
    return this._strings;
  }

  public get mails(): DictionaryOf<MailKeyBioPharmaMail> {
    return this._mails;
  }

  public get initiatives(): DictionaryOf<InitiativePrefixesBioPharmaInitiativeKeyBioPharmaInitiative> {
    return this._initiatives;
  }

  public get insights(): DictionaryOf<InsightBioPharma> {
    return this._insights;
  }

  public get menuEntries(): DictionaryOf<MenuEntryKeyBioPharmaMenuEntry> {
    return this._menuEntries;
  }

  public get tools(): DictionaryOf<ToolBioPharma> {
    return this._tools;
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<boolean> {
    return this._initialized$;
  }

  private flatten(menuEntries: MenuEntryKeyBioPharmaMenuEntry[]): void {

    menuEntries.forEach(entry => {
      if (entry) {
        if (entry.key && entry.value) {
          this._menuEntries[entry.key] = entry;
        }
        if (entry.subMenuEntries) {
          this.flatten(entry.subMenuEntries);
        }
      }
    });
  }

  private replaceFieldsMenuEntries(input: MenuEntryKeyBioPharmaMenuEntry[]): MenuEntryKeyBioPharmaMenuEntry[] {
    return input.map(p => ({
      key: p.key,
      longTitle: p.longTitle?.replaceCompanyAndBrandName(),
      shortTitle: p.shortTitle?.replaceCompanyAndBrandName(),
      prefix: p.prefix?.replaceCompanyAndBrandName(),
      tooltip: p.tooltip?.replaceCompanyAndBrandName(),
      value: p.value?.replaceCompanyAndBrandName(),
      subMenuEntries: (p.subMenuEntries ? this.replaceFieldsMenuEntries(p.subMenuEntries)
        : p.subMenuEntries) as Array<MenuEntryKeyBioPharmaMenuEntry>,
    })) as MenuEntryKeyBioPharmaMenuEntry[];
  }
}
