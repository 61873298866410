/* eslint-disable max-len */
import { ChartDataset } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { ProductInfo } from 'src/app/generated/model/engine/productInfo';
import { ColorTheme } from 'src/color-theme';
import { IStringService } from '../interface';

interface BubbleSource {
  x: number;
  y: number;
  valueInUse: number;
}

export class ChartElement {

  private readonly redColors = [ColorTheme.redFirm, ColorTheme.darkRedFirm, ColorTheme.lightRedFirm];
  private readonly blueColors = [ColorTheme.blueFirm, ColorTheme.darkBlueFirm, ColorTheme.lightBlueFirm];
  private readonly greenColors = [ColorTheme.greenFirm, ColorTheme.darkGreenFirm, ColorTheme.lightGreenFirm];
  private readonly orangeColors = [ColorTheme.orangeFirm, ColorTheme.darkOrangeFirm, ColorTheme.lightOrangeFirm];
  private readonly purpleColors = [ColorTheme.purpleFirm, ColorTheme.darkPurpleFirm, ColorTheme.lightPurpleFirm];
  private readonly yellowColors = [ColorTheme.yellowFirm, ColorTheme.darkYellowFirm, ColorTheme.lightYellowFirm];
  private readonly greyColors = [ColorTheme.greyFirm, ColorTheme.darkGreyFirm, ColorTheme.lightGreyFirm];
  private readonly cyanColors = [ColorTheme.cyanFirm, ColorTheme.darkCyanFirm, ColorTheme.lightCyanFirm];
  private readonly team01Colors = [ColorTheme.team01, ColorTheme.team01d, ColorTheme.team01l];
  private readonly team02Colors = [ColorTheme.team02, ColorTheme.team02d, ColorTheme.team02l];
  private readonly team03Colors = [ColorTheme.team03, ColorTheme.team03d, ColorTheme.team03l];
  private readonly team04Colors = [ColorTheme.team04, ColorTheme.team04d, ColorTheme.team04l];
  private readonly team05Colors = [ColorTheme.team05, ColorTheme.team05d, ColorTheme.team05l];
  private readonly team06Colors = [ColorTheme.team06, ColorTheme.team06d, ColorTheme.team06l];
  private readonly team07Colors = [ColorTheme.team07, ColorTheme.team07d, ColorTheme.team07l];
  private readonly team08Colors = [ColorTheme.team08, ColorTheme.team08d, ColorTheme.team08l];
  private readonly team09Colors = [ColorTheme.team09, ColorTheme.team09d, ColorTheme.team09l];
  private readonly team10Colors = [ColorTheme.team10, ColorTheme.team10d, ColorTheme.team10l];
  private readonly team11Colors = [ColorTheme.team11, ColorTheme.team11d, ColorTheme.team11l];
  private readonly team12Colors = [ColorTheme.team12, ColorTheme.team12d, ColorTheme.team12l];
  private readonly team13Colors = [ColorTheme.team13, ColorTheme.team13d, ColorTheme.team13l];
  private readonly team14Colors = [ColorTheme.team14, ColorTheme.team14d, ColorTheme.team14l];
  private readonly blackColors = [ColorTheme.black, ColorTheme.grey, ColorTheme.grey];

  private firstColorGradient: boolean = true;

  private redIndex = 0;
  private blueIndex = 0;
  private orangeIndex = 0;
  private purpleIndex = 0;
  private greenIndex = 0;
  private yellowIndex = 0;
  private cyanIndex = 0;
  private greyIndex = 0;
  private blackIndex = 0;
  private t01Index = 0;
  private t02Index = 0;
  private t03Index = 0;
  private t04Index = 0;
  private t05Index = 0;
  private t06Index = 0;
  private t07Index = 0;
  private t08Index = 0;
  private t09Index = 0;
  private t10Index = 0;
  private t11Index = 0;
  private t12Index = 0;
  private t13Index = 0;
  private t14Index = 0;


  constructor(private stringService: IStringService) { }

  public initColorChart(valueToSet: number): void {
    this.redIndex = valueToSet;
    this.blueIndex = valueToSet;
    this.orangeIndex = valueToSet;
    this.purpleIndex = valueToSet;
    this.greenIndex = valueToSet;
    this.yellowIndex = valueToSet;
    this.cyanIndex = valueToSet;
    this.blackIndex = valueToSet;
    this.greyIndex = valueToSet;
    this.t01Index = valueToSet;
    this.t02Index = valueToSet;
    this.t03Index = valueToSet;
    this.t04Index = valueToSet;
    this.t05Index = valueToSet;
    this.t06Index = valueToSet;
    this.t07Index = valueToSet;
    this.t08Index = valueToSet;
    this.t09Index = valueToSet;
    this.t10Index = valueToSet;
    this.t11Index = valueToSet;
    this.t12Index = valueToSet;
    this.t13Index = valueToSet;
    this.t14Index = valueToSet;
  }

  public createChartElement(data: number[], label: string, stack: string, color?: string): ChartDataset {
    return this.createChartElementRoot(data, label, stack, color, 1, false);
  }

  public createChartElementColorGradient(data: number[], label: string, stack: string, color?: string): ChartDataset {
    return this.createChartElementRoot(data, label, stack, color, 1, true);
  }

  public createChartElementLightColors(data: number[], label: string, stack: string, transparency: number, color?: string): ChartDataset {
    return this.createChartElementRoot(data, label, stack, color, transparency, false);
  }

  public createDoughnutChartElement(data: number[], label: string, color: string[], opacity: boolean = false, gradient: boolean = true): ChartDataset {
    this.initColorChart(1);
    const chartColorElement = color.map(c => {
      if (!gradient) {
        this.initColorChart(1);
      }
      return this.getColorOf(c, true);
    });
    return {
      data,
      label: this.stringService.strings[label] ? this.stringService.strings[label] : label,
      backgroundColor: opacity ? chartColorElement.map(c => c.replace('1)', '0.8)')) : chartColorElement,
      borderColor: ColorTheme.background,
      hoverBackgroundColor: this.getColorOf('hover'),
      hoverBorderColor: chartColorElement,
      hoverBorderWidth: 4,
      borderWidth: 3,
    };
  }

  public buildBubbleChart(chartDataset: ChartDataset[], productsRows: ProductInfo[], segmentsRows: string[], productsTable: number[][], segmentsDataTable: number[][], xValue: number, yValue: number, firm: string): ChartDataset[] {
    segmentsDataTable.forEach((segmentsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: segmentsTableItem[xValue],
        y: segmentsTableItem[yValue],
        valueInUse: 0
      };
      this.initColorChart(0);
      const elementColor = this.getColorOf('Segments');
      this.initColorChart(2);
      const elementLightColor = this.getColorOf('Segments');
      const foundElement = chartDataset.find(element => element.label === this.stringService.strings[segmentsRows[index]]);
      if (foundElement) {
        foundElement.data.push(newValue);
      } else {
        const newElement: ChartDataset = {
          data: [newValue],
          pointRadius: 5,
          label: this.stringService.strings[segmentsRows[index]],
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          borderColor(context: any): string {
            const indexBorder = context.dataIndex;
            let colorTransparent: string = indexBorder === context.dataset.data.length - 1 ? ColorTheme.black : ColorTheme.grey;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent.toString();
          },
          hoverBorderColor: elementColor,
          hoverBackgroundColor: elementColor,
          stack: 'segments',
          pointStyle: 'crossRot',
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          pointBackgroundColor(context: Context): string {
            const indexPoint = context.dataIndex;
            let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent;
          },
          showLine: false,
          fill: false,
          borderWidth: 3
        };
        chartDataset.push(newElement);
      }
    });
    productsTable.forEach((productsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: productsTableItem[xValue],
        y: productsTableItem[yValue],
        valueInUse: productsTableItem[productsTableItem.length - 1]
      };
      this.initColorChart(0);
      const elementColor = this.getColorOf(productsRows[index] ? productsRows[index].firm : '');
      this.initColorChart(2);
      const elementLightColor = this.getColorOf(productsRows[index] ? productsRows[index].firm : '');
      const foundElement = chartDataset.find(element => productsRows[index] && element.label === productsRows[index].product);
      if (firm === 'all' || productsRows[index].firm === firm) {
        if (foundElement) {
          foundElement?.data.push(newValue);
        } else {
          const newElement: ChartDataset = {
            data: [newValue],
            label: productsRows[index] ? productsRows[index].product : 'Error',
            stack: productsRows[index] ? productsRows[index].firm : 'Error',
            hoverBorderColor: elementColor,
            hoverBackgroundColor: elementColor,
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            pointBackgroundColor: 'transparent',
            pointRadius: (context: any) => context.raw.valueInUse !== 0 ? (2.3 + ((context.raw.valueInUse - 1) * 15)) : 0,
            pointBorderWidth: (context: any) => 4.6 + (10 * (context.raw.valueInUse - 1)),
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            pointBorderColor(context: any): string {
              const indexPoint = context.dataIndex;
              let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
              colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
              return colorTransparent;
            },
            // Hover settings:
            pointHoverBackgroundColor: 'transparent',
            pointHoverRadius: 1,
            pointHoverBorderWidth: (context: any) => 5.1 + (10 * (context.raw.valueInUse - 1)),
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            pointHoverBorderColor(context: any): string {
              const indexPoint = context.dataIndex;
              let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
              colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
              return colorTransparent;
            },
            pointStyle: ('donut'),
            showLine: false,
            fill: false,
            borderWidth: 1
          };
          chartDataset.push(newElement);
        }
      }
    });
    return chartDataset;
  }

  // For e.g. Decisions > Communication > Perceptual Objectives
  public buildSegmentBubbleChart(chartDataset: ChartDataset[], segmentsRows: string[], segmentsDataTable: number[][], xValue: number, yValue: number): ChartDataset[] {
    segmentsDataTable.forEach((segmentsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: segmentsTableItem[xValue],
        y: segmentsTableItem[yValue],
        valueInUse: 0
      };
      this.initColorChart(0);
      const elementColor = this.getColorOf(segmentsRows[index]);
      const elementColorTransparent = elementColor.replace('1)', '0.5)');
      const segmentColor = this.getColorOf('Segments');
      this.initColorChart(2);
      const elementLightColor = this.getColorOf(segmentsRows[index]);

      const foundElement = chartDataset.find(element => element.label === this.stringService.strings[segmentsRows[index]]);
      if (foundElement) {
        foundElement.data.push(newValue);
      } else {
        const newElement: ChartDataset = {
          data: [newValue],
          backgroundColor: elementColor,
          pointRadius: 5,
          label: this.stringService.strings[segmentsRows[index]],
          datalabels: { color: 'white', backgroundColor: elementColorTransparent },
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          borderColor(context: any): string {
            const indexBorder = context.dataIndex;
            let colorTransparent: string = indexBorder === context.dataset.data.length - 1 ? ColorTheme.black : ColorTheme.grey;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent.toString();
          },
          hoverBorderColor: segmentColor,
          hoverBackgroundColor: segmentColor,
          stack: 'segments',
          pointStyle: 'crossRot',
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          pointBackgroundColor(context: Context): string {
            const indexPoint = context.dataIndex;
            let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent;
          },
          showLine: false,
          fill: false,
          borderWidth: 3
        };
        chartDataset.push(newElement);
      }
    });

    return chartDataset;
  }

  // For e.g. Decisions > Portfolio > R&D
  public buildTechnicalBubbleChart(
    chartDataset: ChartDataset[],
    productsRows: ProductInfo[],
    productsTable: number[][],
    segmentsRows: string[] = [],
    segmentsDataTable: number[][] = []): ChartDataset[] {

    segmentsDataTable?.forEach((segmentsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: segmentsTableItem[0],
        y: segmentsTableItem[1],
        valueInUse: 0
      };
      this.initColorChart(0);
      const elementColor = this.getColorOf('Segments');
      this.initColorChart(2);
      const elementLightColor = this.getColorOf('Segments');
      const foundElement = chartDataset.find(element => element.label === this.stringService.strings[segmentsRows[index]]);
      if (foundElement) {
        foundElement.data.push(newValue);
      } else {
        const newElement: ChartDataset = {
          data: [newValue],
          pointRadius: 5,
          label: this.stringService.strings[segmentsRows[index]],
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          borderColor(context: any): string {
            const indexBorder = context.dataIndex;
            let colorTransparent: string = indexBorder === context.dataset.data.length - 1 ? ColorTheme.black : ColorTheme.grey;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent.toString();
          },
          hoverBorderColor: elementColor,
          hoverBackgroundColor: elementColor,
          stack: 'segments',
          pointStyle: 'crossRot',
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          pointBackgroundColor(context: Context): string {
            const indexPoint = context.dataIndex;
            let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent;
          },
          showLine: false,
          fill: false,
          borderWidth: 3
        };
        chartDataset.push(newElement);
      }
    });

    productsTable?.forEach((productsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: productsTableItem[0],
        y: productsTableItem[1],
        valueInUse: 0
      };
      this.initColorChart(0);
      const colorStr = productsRows[index] ?
        ((productsRows[index].firm === 'None' && productsRows[index].product?.startsWith('%')) ? 'NoneGrey' : productsRows[index].firm) : '';
      const elementColor = this.getColorOf(colorStr);
      this.initColorChart(2);
      const elementLightColor = this.getColorOf(colorStr);
      const foundElement = chartDataset.find(element => productsRows[index] && element.label === productsRows[index].product);
      if (foundElement) {
        foundElement?.data.push(newValue);
      } else {
        const productName = productsRows[index] ? (productsRows[index]?.product ?? '') : 'Error';
        const stack = productsRows[index] ? productsRows[index].firm : 'Error';
        const newElement: ChartDataset = {
          data: [newValue],
          label: productName,
          stack,
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          borderColor(context: any): string {
            const indexBorder = context.dataIndex;
            return indexBorder === context.dataset.data.length - 1 ? 'black' : 'grey';
          },
          hoverBorderColor: elementColor,
          hoverBackgroundColor: elementColor,
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          pointBackgroundColor(context: any): string {
            const indexPoint = context.dataIndex;
            let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent;
          },
          pointRadius: (stack === 'None') ? 8 : 4,
          pointStyle: ('circle'),
          backgroundColor: elementColor,
          showLine: false,
          fill: false,
          borderWidth: (stack === 'None') ? 0 : 1,
        };
        chartDataset.push(newElement);
      }
    });
    return chartDataset;
  }

  // For e.g. Decisions > Communication > Perceptual Objectives
  public buildProductBubbleChart(
    chartDataset: ChartDataset[],
    productsRows: ProductInfo[],
    productsTable: number[][],
    xValue: number,
    yValue: number,
    productName: string): ChartDataset[] {

    productsTable.forEach((productsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: productsTableItem[xValue],
        y: productsTableItem[yValue],
        valueInUse: 0
      };
      this.initColorChart(0);
      const elementColor = this.getColorOf(productsRows[index] ? productsRows[index].firm : '');
      this.initColorChart(2);
      const elementLightColor = this.getColorOf(productsRows[index] ? productsRows[index].firm : '');
      const foundElement = chartDataset.find(element => productsRows[index] && element.label === productsRows[index].product);
      if (productsRows[index] && (productsRows[index].product === productName)) {
        if (foundElement) {
          foundElement?.data.push(newValue);
        } else {
          const newElement: ChartDataset = {
            data: [newValue],
            label: productsRows[index] ? productsRows[index].product : 'Error',
            stack: productsRows[index] ? productsRows[index].firm : 'Error',
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            borderColor(context: any): string {
              const indexBorder = context.dataIndex;
              return indexBorder === context.dataset.data.length - 1 ? 'black' : 'grey';
            },
            hoverBorderColor: elementColor,
            hoverBackgroundColor: elementColor,
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            pointBackgroundColor(context: any): string {
              const indexPoint = context.dataIndex;
              let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
              colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
              return colorTransparent;
            },
            pointStyle: 'circle',
            pointRadius: 4,
            backgroundColor: elementColor,
            showLine: false,
            fill: false,
            borderWidth: 1
          };
          chartDataset.push(newElement);
        }
      }
    });
    return chartDataset;
  }

  public buildPeriodForecastLineChart(lineList: string[], chartData: (number | null)[][],
    selectedPeriod: number, colorList?: string[], forecast: boolean = true, gradient: boolean = false): ChartDataset[] {
    const chartToReturn: ChartDataset[] = [];
    this.initColorChart(0);
    // Previous periods to current
    for (let i = 0; i < lineList.length; i++) {
      const dataPoints: (number | null)[] = [];
      chartData.forEach((table, tableIndex) => {
        if (tableIndex <= selectedPeriod + 1) {
          dataPoints.push(table[i]);
        }
      });
      if (!gradient) { this.initColorChart(0); }
      const color = colorList ? this.getColorOf(colorList[i]) : this.getColorOf(lineList[i]);
      chartToReturn.push({
        data: dataPoints,
        label: this.stringService.strings[lineList[i]] ? this.stringService.strings[lineList[i]] : lineList[i],
        type: 'line',
        tension: 0.1,
        pointBackgroundColor: color,
        borderColor: color,
        datalabels: { color: 'white', backgroundColor: color }
      });
    }

    if (forecast) {
      this.initColorChart(0);
      // Current to forecast
      for (let i = 0; i < lineList.length; i++) {
        const dataPoints: (number | null)[] = [];
        chartData.forEach((table, tableIndex) => {
          if (tableIndex <= selectedPeriod) {
            dataPoints.push(null);
          } else {
            dataPoints.push(table[i]);
          }
        });
        const color = colorList ? this.getColorOf(colorList[i]) : this.getColorOf(lineList[i]);
        chartToReturn.push({
          data: dataPoints,
          label: this.stringService.strings[(lineList[i])],
          borderDash: [5],
          type: 'line',
          tension: 0.1,
          pointBackgroundColor: color,
          borderColor: color,
          datalabels: { color: 'white', backgroundColor: color }
        });
      }
    }

    return chartToReturn;
  }

  private createChartElementRoot(data: number[], label: string, stack: string, color?: string, transparency: number = 1, gradient: boolean = false): ChartDataset {
    let chartColorElement;
    const stackColorIndex = stack.indexOf('#');
    const stackColor = (stackColorIndex === -1) ? stack : stack.substring(stackColorIndex + 1);
    const stackContent = (stackColorIndex === -1) ? stack : stack.substring(0, stackColorIndex);
    if (color) { chartColorElement = color; }
    else {
      if (!gradient || this.firstColorGradient) {
        this.initColorChart(1);
        this.firstColorGradient = false;
      }

      chartColorElement = this.getColorOf(stackColor, true);
    }
    return {
      data,
      label: this.stringService.strings[label] ? this.stringService.strings[label] : label,
      backgroundColor: transparency !== 1 ? chartColorElement.replace('1)', `${transparency})`) : chartColorElement,
      borderColor: chartColorElement,
      pointBackgroundColor: chartColorElement,
      pointHoverBackgroundColor: chartColorElement,
      pointHoverBorderColor: chartColorElement,
      hoverBackgroundColor: this.getColorOf('hover'),
      hoverBorderColor: chartColorElement,
      hoverBorderWidth: 4,
      stack: stackContent
    };
  }

  private rgbaStringToArray(rgba: string): number[] {
    const matches = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d+)?\)/);
    if (matches) {
      return matches.slice(1).map(value => parseFloat(value));
    }
    return [128, 128, 128, 1];
  }

  private incrementColorIndex(index: number, length: number, isLighter: boolean = false): number {
    if (isLighter) {
      return (index < 4) ? (index + 1) : 0;
    }
    return (index < (length - 1)) ? (index + 1) : 0;
  }

  private color(colorTheme: ColorTheme[], index: number, isLighter: boolean = false): string {
    if (isLighter) {
      if (index <= 1) {
        return colorTheme[1];
      }
      const colors = this.rgbaStringToArray(colorTheme[1]);
      return `rgba(${Math.round(colors[0] + ((255 - colors[0]) * (index - 1) / 4.5))},`
        + `${Math.round(colors[1] + ((255 - colors[1]) * (index - 1) / 4.5))},`
        + `${Math.round(colors[2] + ((255 - colors[2]) * (index - 1) / 4.5))},1)`;
    }
    return colorTheme[index];
  }

  private getColorOf(stack: string, isLighter: boolean = false): string {
    let colorToReturn: string;
    switch (stack) {
      case 'Team_1': {
        colorToReturn = this.color(this.team01Colors, this.t01Index, isLighter);
        this.t01Index = this.incrementColorIndex(this.t01Index, this.team01Colors.length, isLighter);
        break;
      }
      case 'Team_2': {
        colorToReturn = this.color(this.team02Colors, this.t02Index, isLighter);
        this.t02Index = this.incrementColorIndex(this.t02Index, this.team02Colors.length, isLighter);
        break;
      }
      case 'Team_3': {
        colorToReturn = this.color(this.team03Colors, this.t03Index, isLighter);
        this.t03Index = this.incrementColorIndex(this.t03Index, this.team03Colors.length, isLighter);
        break;
      }
      case 'Team_4': {
        colorToReturn = this.color(this.team04Colors, this.t04Index, isLighter);
        this.t04Index = this.incrementColorIndex(this.t04Index, this.team04Colors.length, isLighter);
        break;
      }
      case 'Team_5': {
        colorToReturn = this.color(this.team05Colors, this.t05Index, isLighter);
        this.t05Index = this.incrementColorIndex(this.t05Index, this.team05Colors.length, isLighter);
        break;
      }
      case 'Team_6': {
        colorToReturn = this.color(this.team06Colors, this.t06Index, isLighter);
        this.t06Index = this.incrementColorIndex(this.t06Index, this.team06Colors.length, isLighter);
        break;
      }
      case 'Team_7': {
        colorToReturn = this.color(this.team07Colors, this.t07Index, isLighter);
        this.t07Index = this.incrementColorIndex(this.t07Index, this.team07Colors.length, isLighter);
        break;
      }
      case 'Team_8': {
        colorToReturn = this.color(this.team08Colors, this.t08Index, isLighter);
        this.t08Index = this.incrementColorIndex(this.t08Index, this.team08Colors.length, isLighter);
        break;
      }
      case 'Team_9': {
        colorToReturn = this.color(this.team09Colors, this.t09Index, isLighter);
        this.t09Index = this.incrementColorIndex(this.t09Index, this.team09Colors.length, isLighter);
        break;
      }
      case 'Team_10': {
        colorToReturn = this.color(this.team10Colors, this.t10Index, isLighter);
        this.t10Index = this.incrementColorIndex(this.t10Index, this.team10Colors.length, isLighter);
        break;
      }
      case 'Team_11': {
        colorToReturn = this.color(this.team11Colors, this.t11Index, isLighter);
        this.t11Index = this.incrementColorIndex(this.t11Index, this.team11Colors.length, isLighter);
        break;
      }
      case 'Team_12': {
        colorToReturn = this.color(this.team12Colors, this.t12Index, isLighter);
        this.t12Index = this.incrementColorIndex(this.t12Index, this.team12Colors.length, isLighter);
        break;
      }
      case 'Team_13': {
        colorToReturn = this.color(this.team13Colors, this.t13Index, isLighter);
        this.t13Index = this.incrementColorIndex(this.t13Index, this.team13Colors.length, isLighter);
        break;
      }
      case 'Team_14': {
        colorToReturn = this.color(this.team14Colors, this.t14Index, isLighter);
        this.t14Index = this.incrementColorIndex(this.t14Index, this.team14Colors.length, isLighter);
        break;
      }
      case 'Firm_C':
      case 'Firm_1': {
        colorToReturn = this.color(this.redColors, this.redIndex, isLighter);
        this.redIndex = this.incrementColorIndex(this.redIndex, this.redColors.length, isLighter);
        break;
      }
      case 'Firm_E':
      case 'Firm_2': {
        colorToReturn = this.color(this.blueColors, this.blueIndex, isLighter);
        this.blueIndex = this.incrementColorIndex(this.blueIndex, this.blueColors.length, isLighter);
        break;
      }
      case 'Firm_N':
      case 'Firm_3': {
        colorToReturn = this.color(this.orangeColors, this.orangeIndex, isLighter);
        this.orangeIndex = this.incrementColorIndex(this.orangeIndex, this.orangeColors.length, isLighter);
        break;
      }
      case 'Firm_T':
      case 'Firm_4':
      case 'Fulfillment': {
        colorToReturn = this.color(this.purpleColors, this.purpleIndex, isLighter);
        this.purpleIndex = this.incrementColorIndex(this.purpleIndex, this.purpleColors.length, isLighter);
        break;
      }
      case 'Firm_R':
      case 'Firm_5': {
        colorToReturn = this.color(this.greenColors, this.greenIndex, isLighter);
        this.greenIndex = this.incrementColorIndex(this.greenIndex, this.greenColors.length, isLighter);
        break;
      }
      case 'Firm_X':
      case 'Firm_6': {
        colorToReturn = this.color(this.yellowColors, this.yellowIndex, isLighter);
        this.yellowIndex = this.incrementColorIndex(this.yellowIndex, this.yellowColors.length, isLighter);
        break;
      }
      case 'Firm_7': {
        colorToReturn = this.color(this.cyanColors, this.cyanIndex, isLighter);
        this.cyanIndex = this.incrementColorIndex(this.cyanIndex, this.cyanColors.length, isLighter);
        break;
      }
      case 'Seg_Energy':
      case 'Indication_Prevention':
      case 'Indication_Stage_II':
      case 'Adherence':
      case 'NeedEfficacy_AcutePostOpPainReduction': {
        return ColorTheme.greenEnergy;
      }
      case 'Seg_Appliances':
      case 'Indication_Stage_I':
      case 'NeedEfficacy_DelayDiseaseProgressionToStageIII': {
        return ColorTheme.blueAppliances;
      }
      case 'Seg_Automotive':
      case 'Indication_Treatment':
      case 'Indication_Stage_III':
      case 'Persistence':
      case 'NeedEfficacy_GeneralPainReduction': {
        return ColorTheme.redAutomobile;
      }
      case 'Seg_Industrial':
      case 'NeedEfficacy_HasALongDurationOfAction': {
        return ColorTheme.greyIndustrial;
      }
      case 'Seg_Aerospace':
      case 'NeedEfficacy_HasARapidOnsetOfAction': {
        return ColorTheme.yellowAerospace;
      }
      case 'Seg_Innovators': {
        return ColorTheme.greenInnovators;
      }
      case 'Seg_Followers': {
        return ColorTheme.yellowFollowers;
      }
      case 'Seg_Adopters': {
        return ColorTheme.blueAdopters;
      }
      case 'NeedEfficacy_Improve3YearSurvival': {
        return ColorTheme.team01;
      }
      case 'NeedEfficacy_NormalizesLigamentRegeneration': {
        return ColorTheme.team03;
      }
      case 'NeedEfficacy_ImprovesRangeOfMotion': {
        return ColorTheme.team05;
      }
      case 'NeedEfficacy_PreventInflammatoryRelapseInStageIII': {
        return ColorTheme.lightRedFirm;
      }
      case 'NeedEfficacy_ReducesMetabolite5cLevels': {
        return ColorTheme.lightBlueFirm;
      }
      case 'NeedEfficacy_ReducesNeedForJointSurgery': {
        return ColorTheme.lightOrangeFirm;
      }
      case 'NeedEfficacy_ReductionOfBleedingEpisodes': {
        return ColorTheme.lightPurpleFirm;
      }
      case 'NeedEfficacy_SIMsReduction': {
        return ColorTheme.lightGreenFirm;
      }
      case 'NeedSafety_AllergicReactions': {
        return ColorTheme.lightYellowFirm;
      }
      case 'NeedSafety_DehydrateMucosa': {
        return ColorTheme.lightGreyFirm;
      }
      case 'NeedSafety_DistalNerveTingling': {
        return ColorTheme.lightCyanFirm;
      }
      case 'NeedSafety_DoesNotCauseFluidRetention': {
        return ColorTheme.darkRedFirm;
      }
      case 'NeedSafety_DoesNotCauseHypertension': {
        return ColorTheme.darkBlueFirm;
      }
      case 'NeedSafety_DoesNotCauseSorenessOfJointsAndLigaments': {
        return ColorTheme.darkOrangeFirm;
      }
      case 'NeedSafety_DoesNotIncreaseRiskOfMinorInfection': {
        return ColorTheme.darkPurpleFirm;
      }
      case 'NeedSafety_HasAFavorableLongTermSafetyProfile': {
        return ColorTheme.darkGreenFirm;
      }
      case 'NeedSafety_Headache': {
        return ColorTheme.darkYellowFirm;
      }
      case 'NeedSafety_Immunosuppression': {
        return ColorTheme.darkGreyFirm;
      }
      case 'NeedSafety_LongTermSafetyProfile': {
        return ColorTheme.darkCyanFirm;
      }
      case 'NeedSafety_Mildfever': {
        return ColorTheme.black;
      }
      case 'Convenience': {
        return ColorTheme.brownSemantic;
      }
      case 'Segments': {
        colorToReturn = this.color(this.blackColors, this.blackIndex, isLighter);
        this.blackIndex = this.incrementColorIndex(this.blackIndex, this.blackColors.length, isLighter);
        break;
      }
      case 'hover': {
        return ColorTheme.hover;
      }
      case 'None': {
        return ColorTheme.decision;
      }
      case 'NoneGrey': {
        return ColorTheme.grey;
      }
      case 'red': {
        colorToReturn = this.color(this.redColors, this.redIndex, isLighter);
        this.redIndex = this.incrementColorIndex(this.redIndex, this.redColors.length, isLighter);
        break;
      }
      case 'blue': {
        colorToReturn = this.color(this.blueColors, this.blueIndex, isLighter);
        this.blueIndex = this.incrementColorIndex(this.blueIndex, this.blueColors.length, isLighter);
        break;
      }
      case 'orange': {
        colorToReturn = this.color(this.orangeColors, this.orangeIndex, isLighter);
        this.orangeIndex = this.incrementColorIndex(this.orangeIndex, this.orangeColors.length, isLighter);
        break;
      }
      case 'purple': {
        colorToReturn = this.color(this.purpleColors, this.purpleIndex, isLighter);
        this.purpleIndex = this.incrementColorIndex(this.purpleIndex, this.purpleColors.length, isLighter);
        break;
      }
      case 'green': {
        colorToReturn = this.color(this.greenColors, this.greenIndex, isLighter);
        this.greenIndex = this.incrementColorIndex(this.greenIndex, this.greenColors.length, isLighter);
        break;
      }
      case 'yellow': {
        colorToReturn = this.color(this.yellowColors, this.yellowIndex, isLighter);
        this.yellowIndex = this.incrementColorIndex(this.yellowIndex, this.yellowColors.length, isLighter);
        break;
      }
      case 'grey': {
        colorToReturn = this.color(this.greyColors, this.greyIndex, isLighter);
        this.greyIndex = this.incrementColorIndex(this.greyIndex, this.greyColors.length, isLighter);
        break;
      }
      case 'black': {
        colorToReturn = this.color(this.blackColors, this.blackIndex, isLighter);
        this.blackIndex = this.incrementColorIndex(this.blackIndex, this.blackColors.length, isLighter);
        break;
      }
      case 'cyan': {
        colorToReturn = this.color(this.cyanColors, this.cyanIndex, isLighter);
        this.cyanIndex = this.incrementColorIndex(this.cyanIndex, this.cyanColors.length, isLighter);
        break;
      }
      default: {
        const color = stack as ColorTheme;
        if (color) {
          return color;
        }
        return ColorTheme.redFirm;
      }
    }
    return colorToReturn;
  }
}
