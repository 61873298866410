/* eslint-disable max-len */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from 'src/app/services';
import { CryptoHelper, TokenStorage } from 'src/app/helpers';

import * as Generated from 'src/app/generated/api/auth/account-api';
import { AccountModification, UserAccount, UserAccountCreated, UserAccountCreation, UserRole, UserSelection, UsersSelection } from 'src/app/generated/model/auth';


@Injectable({
  providedIn: 'root'
})
export class AccountApi extends Generated.AccountApi {
  private header: HttpHeaders;

  constructor(http: HttpClient,
    configService: ConfigService,
    private tokenStorage: TokenStorage,
    private cryptoHelper: CryptoHelper) {
    super(http, configService);

    const token = this.tokenStorage.getToken();

    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
  }

  public override getPriviledgeUserAccounts(): Observable<UserAccount[]> {

    return super.getPriviledgeUserAccounts(this.header)
      .pipe(catchError(error => {
        console.error(`getPriviledgeUsers failed`, error);
        // return throwError(error);
        return EMPTY;
      }));
  }

  public getUserAccounts(): Observable<UserAccount[]> {
    return super.getAccountsUserAccounts(this.header)
      .pipe(catchError(error => {
        console.error(`getUserAccounts failed`, error);
        // return throwError(error);
        return EMPTY;
      }));
  }

  public override getExistsLogin(
    login: string,
  ): Observable<boolean> {
    return super.getExistsLogin(login, this.header)
      .pipe(catchError(error => {
        console.error(`getExistsLogin failed`, error);
        // return throwError(error);
        return EMPTY;
      }));
  }

  public override getExistsEmail(
    login: string,
  ): Observable<boolean> {
    return super.getExistsEmail(login, this.header)
      .pipe(catchError(error => {
        console.error(`getExistsEmail failed`, error);
        // return throwError(error);
        return EMPTY;
      }));
  }

  public override getExistsName(
    login: string,
  ): Observable<boolean> {
    return super.getExistsName(login, this.header)
      .pipe(catchError(error => {
        console.error(`getExistsName failed`, error);
        // return throwError(error);
        return EMPTY;
      }));
  }


  public getFacilitators(): Observable<UserAccount[]> {
    return super.getFacilitatorsUserAccounts(this.header)
      .pipe(catchError(error => {
        console.error(`getFacilitators failed`, error);
        // return throwError(error);
        return EMPTY;
      }));
  }

  public getFacilitator(uuid: string): Observable<UserAccount> {
    return super.getFacilitatorUserAccount(uuid, this.header)
      .pipe(catchError(error => {
        console.error(`getFacilitators failed`, error);
        // return throwError(error);
        return EMPTY;
      }));
  }

  public getUser(): Observable<UserRole> {
    const token = this.tokenStorage.getToken();

    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
    return super.getMeUserRole(this.header);
  }

  public connect(fromUrl: string): Observable<UserRole> {
    const token = this.tokenStorage.getToken();

    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
    return super.postConnectUserRole(encodeURIComponent(fromUrl), this.header);
  }


  public getUserAccount(
    uuid: string,
  ): Observable<UserAccountCreation | undefined> {
    return super.getAccount(uuid, this.header).pipe(map(encrypt => {
      if (encrypt) {
        const json = this.cryptoHelper.symetricDecrypt(encrypt);
        const userCreation: UserAccountCreation = JSON.parse(json);

        if (userCreation.password) {
          userCreation.password = this.cryptoHelper.symetricDecrypt(userCreation.password);
        }

        return userCreation;
      }

      return undefined;
    }));
  }

  public getSeminarUserAccounts(
    usersSelection: UsersSelection,
  ): Observable<UserAccount[]> {
    return super.postGetSeminarUsersUserAccounts(usersSelection, this.header)
      .pipe(catchError(error => {
        console.error(`postGetSeminarUsersUserAccounts failed`, error);
        return EMPTY;
      }));
  }

  public createUser(
    userCreation: UserAccountCreation,
  ): Observable<UserAccountCreated> {
    const encrypt: string = this.cryptoHelper.symetricEncrypt(JSON.stringify(userCreation));
    return super.postCreateUserUserAccountCreated(encrypt, this.header);
  }

  public updateUser(
    accountCreation: AccountModification,
  ): Observable<UserAccountCreated> {
    const encrypt: string = this.cryptoHelper.symetricEncrypt(JSON.stringify(accountCreation));

    return super.putUpdateUserAccountCreated(encrypt, this.header);
  }

  public override delete(uuid: string): Observable<any> {
    return super.delete(uuid, this.header);
  }

  public deleteUsers(usersSelection: UsersSelection): Observable<any> {
    return super.postDeleteUsers(usersSelection, this.header);
  }

  public deleteUsersForever(usersSelection: UsersSelection): Observable<any> {
    return super.postDeleteForeverUsers(usersSelection, this.header);
  }

  public override deleteForever(uuid: string): Observable<any> {
    return super.deleteForever(uuid, this.header);
  }

  public restore(uuid: string): Observable<any> {
    return super.postRestore(uuid, this.header);
  }

  public unlock(uuid: string): Observable<any> {
    return super.postUnlockUserAccount(uuid, this.header);
  }

  public lock(uuid: string): Observable<any> {
    return super.postLockUserAccount(uuid, this.header);
  }

  public clearDate(uuid: string): Observable<any> {
    return super.postClearDate(uuid, this.header);
  }

  public sendMail(uuid: string): Observable<number> {
    return super.postSendMail({ uuid }, this.header);
  }

  public sendMails(usersSelection: UsersSelection): Observable<number> {
    return super.postSendMails(usersSelection, this.header);
  }
}
